<template>
  <a-modal
      :title="title"
      :width="720"
      :visible="visible"
      :confirmLoading="confirmLoading"
      :maskClosable="false"
      @ok="handleSubmit"
      @cancel="handleCancel"
  >
    <a-spin :spinning="confirmLoading">
      <a-form :form="form">
        <a-form-item v-if="mode=='add'" :colon="false" label=" " :labelCol="labelCol" :wrapperCol="wrapperCol">
          <div style="text-align: right"><a @click="showDataSearchModle">从货物品名库选择</a></div>
        </a-form-item>
        <a-form-item label="货物品名" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
              placeholder="请输入货物品名"
              v-decorator="['goods_name', {rules: [{required: true, message: '请输入货物品名'}]}]"
          />
        </a-form-item>

        <a-form-item label="危化品类别" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-input
              placeholder="请输入危化品类别"
              v-decorator="['whplb', {rules: [{ message: '请输入危化品类别'}]}]"
          />
        </a-form-item>
<!--        <a-form-item label="说明书文件" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
<!--          <UploadFile-->
<!--              :file="record.instructions"-->
<!--              v-decorator="['instructions_file_id']"-->
<!--          />-->
<!--        </a-form-item>-->
<!--        <a-form-item label="二维码文件" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
<!--          <UploadFile-->
<!--              :file="record.qrcode"-->
<!--              v-decorator="['qrcode_file_id']"-->
<!--          />-->
<!--        </a-form-item>-->
<!--        <a-form-item label="安全卡文件" :labelCol="labelCol" :wrapperCol="wrapperCol">-->
<!--          <UploadFile-->
<!--              :file="record.securitycard"-->
<!--              v-decorator="['securitycard_file_id']"-->
<!--          />-->
<!--        </a-form-item>-->
        <a-form-item label="启用状态" :labelCol="labelCol" :wrapperCol="wrapperCol">
          <a-radio-group
              v-decorator="['status', { initialValue: 0,rules: [{ required: true }] }]"
          >
            <a-radio :value="0">启用</a-radio>
            <a-radio :value="1">停用</a-radio>
          </a-radio-group>
        </a-form-item>
      </a-form>
    </a-spin>
    <goods-name-search-model ref="GoodsNameSearchModel" @handleSuccess="setFormData"/>
  </a-modal>
</template>

<script>

import * as Api from '@/api/data/goodsName'
import {AddressSearchModel, GoodsNameSearchModel, UploadFile} from '@/components'
import _ from "lodash";

export default {
  components: {
    AddressSearchModel,
    UploadFile,
    GoodsNameSearchModel
  },
  data() {
    return {
      // 对话框标题
      title: '',
      // 标签布局属性
      labelCol: {
        span: 7
      },
      // 输入框布局属性
      wrapperCol: {
        span: 13
      },
      // modal(对话框)是否可见
      visible: false,
      // modal(对话框)确定按钮 loading
      confirmLoading: false,
      // 当前表单元素
      form: this.$form.createForm(this),
      // 当前记录
      record: {},
      // 当前模式 add新增 edit编辑
      mode: ""
    }
  },
  methods: {
    showDataSearchModle() {
      this.$refs.GoodsNameSearchModel.open()
    },
    setFormData(item) {
      this.form.setFieldsValue({
        goods_name: item.goods_name,
        whplb: item.whplb,
      })
    },
    /**
     * 显示对话框
     */
    add() {
      this.mode = "add"
      this.title = '新增' + this.$route.meta.title
      this.visible = true
    },
    edit(record) {
      this.mode = "edit"
      this.title = '编辑' + this.$route.meta.title
      this.visible = true

      this.record = record
      this.setFieldsValue()
    },

    /**
     * 设置默认值
     */
    setFieldsValue() {
      const {form: {setFieldsValue}} = this
      this.$nextTick(() => {
        const data = _.pick(this.record, ['goods_name', 'whplb', 'instructions_file_id', 'qrcode_file_id', 'securitycard_file_id', 'status'])
        setFieldsValue(data)
      })
    },

    /**
     * 确认按钮
     */
    handleSubmit(e) {
      e.preventDefault()
      const {form: {validateFields}} = this
      // 表单验证
      validateFields((errors, values) => {
        // 提交到后端api
        !errors && this.onFormSubmit(values)
      })
    },

    /**
     * 关闭对话框事件
     */
    handleCancel() {
      this.visible = false
      this.form.resetFields()
    },

    /**
     * 提交到后端api
     */
    onFormSubmit(values) {
      this.confirmLoading = true
      var sendApi = null
      if (this.mode == "add") {
        sendApi = Api.add({form: values})
      } else {
        sendApi = Api.edit({goodsId: this.record['goods_id'], form: values})
      }
      sendApi.then((result) => {
        // 显示成功
        this.$message.success(result.message, 1.5)
        // 关闭对话框
        this.handleCancel()
        // 通知父端组件提交完成了
        this.$emit('handleSubmit', values)
      })
          .finally((result) => {
            this.confirmLoading = false
          })
    }

  }
}
</script>
